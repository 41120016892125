import React, { useEffect, useState, Suspense, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from 'components/PrivateLayout/AppBar';
import Sidebar from 'components/PrivateLayout/Sidebar';
import PageLoader from 'components/PageLoader';
import ThemeContext from 'themes/ThemeContext';

const drawerWidth = 250;
const closeDrawer = 86;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    background: '#EEE',
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? `-${drawerWidth}px` : `-${0}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Layout = ({
  name,
  initials,
  src,
  logo,
  companyName,
  children,
  sidebar,
  hidderRoute,
  handleMenu,
  handleOpenModal,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(sidebar[0]);
  const [selectId, setSelectId] = useState(null);
  const [subMenu, setSubMenu] = useState(null);
  const [openDropDown, setOpenDropDown] = useState(false);

  const handleToggle = () => {
    setOpenDropDown(!openDropDown);
  };

  useEffect(() => {
    const selectedItem = sidebar.find((e) => {
      return (
        e.path?.includes(pathname) ||
        e.path?.includes('/' + pathname.split('/')[1])
      );
    });
    if (selectedItem) {
      setSelected(selectedItem);
    }
  }, [pathname, sidebar]);

  const handleNavigate = (ele) => {
    navigate(ele.path);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        name={name}
        initials={initials}
        src={src}
        open={open}
        handleDrawer={handleDrawer}
        selected={selected}
        handleMenu={handleMenu}
        handleOpenModal={handleOpenModal}
      />

      <Drawer
        sx={{
          width: open ? drawerWidth : closeDrawer,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : closeDrawer,
            boxSizing: 'border-box',
            background: theme?.palette?.common?.main?.white,
            zIndex: 1,
            transform: 'translateX(0) !important',
            visibility: 'visible !important',
            margin: '30px 0px 0px 14px',
            borderRadius: '10px',
            height: '95%',
          },
        }}
        className={
          open ? 'auth-sidebar-drawer' : 'auth-sidebar-drawer side-width'
        }
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Sidebar
          open={open}
          openDropDown={openDropDown}
          sidebar={sidebar}
          selected={selected}
          selectId={selectId}
          setSelected={setSelected}
          logo={logo}
          companyName={companyName}
          handleNavigate={handleNavigate}
          handleToggle={handleToggle}
          handleOpenModal={handleOpenModal}
        />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Suspense
          fallback={
            <div className="container">
              <PageLoader />
            </div>
          }
        >
          {children}
        </Suspense>
      </Main>
    </Box>
  );
};

export default Layout;
