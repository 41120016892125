import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid } from '@mui/material';
import Button from 'components/Button';
import ConfirmImg from 'assets/svg/confirm-popup.svg';

const ConfirmModalForm = ({ handleClose, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12} lg={12}>
          <Box textAlign={'center'}>
            <img src={ConfirmImg} alt="ConfirmImg" />
          </Box>
          <Box mb={2} p={2} borderRadius={2}>
            <Typography
              textAlign={'center'}
              fontSize={props?.fullScreen ? 16 : 18}
              fontWeight={500}
            >
              {t(props?.confirmMsg)}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={12} md={12} lg={12}>
          <Box mb={2} display={'flex'} justifyContent={'center'}>
            <Button
              variant="outlined"
              type="button"
              onClick={handleClose}
              sx={{ marginRight: 2, minWidth: props?.fullScreen ? 100 : 200 }}
            >
              {t('Cancel')}
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={props?.handleSubmit}
              loading={props?.loading}
              loadingText={'Saving...'}
              disabled={props?.loading}
              sx={{
                minWidth: props?.fullScreen ? 100 : 200,

                backgroundColor: props?.theme?.palette?.darkBlue?.main,
              }}
            >
              {t(props?.btnMsg)}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmModalForm;
