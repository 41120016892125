import React from 'react';
import { Box } from '@mui/material';

const MyLogo = (props) => {
  return (
    <Box textAlign={'center'} className={props?.className}>
      <img src={props?.src} alt={props?.alt || 'logo'} {...props} />
    </Box>
  );
};

export default MyLogo;
