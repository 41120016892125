import React from 'react';
import { Box } from '@mui/material';
import Button from 'components/Button';

const MarkAsReadContent = ({ handleUpdateMarkNotification, notifyCount }) => {
  return (
    <Box className="">
      <Button
        variant="outlined"
        sx={{
          minWidth: '100px',
          padding: '0px !important',
          background: 'none',
          border: 'none',
          borderBottom: '1px solid #6B77E1',
          borderRadius: '0px !important',
          '&:hover': {
            border: 'none',
            backgroundColor: 'transparent', // optional to prevent default hover effects
          }
        }}
        type="button"
        onClick={handleUpdateMarkNotification}
        fullWidth
        disabled={notifyCount === 0}
      >
        Mark all as read
      </Button>
    </Box>
  );
};

export default MarkAsReadContent;
