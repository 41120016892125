import React, { Component } from 'react';
import { createTheme } from '@mui/material/styles';

// const white = '#FFFFFF';
// const gray = '#DDDDDD';
// const black = '#000000';
// const light = '#E4DAFF';

// --------------------NEW THEME------------------------
// const GreekSea = '#6B77E1';
// const lightGray = '#4B4D5F';
// const secondary = '#A5A6AF';
// const lightGradient = 'linear-gradient(to right, #a6afff, #bca5ff)';
// const darkGradient = 'linear-gradient(to right, #e65100c4, #e65100db)';
// const darkBlue = '#576AA4';

const PRIMARY = '#A6AFFF';
const SECONDARY = '#BCA5FF';
const TERRITORY = 'gray';
// const LIGHT_GRAY = '#EEEEEE';
const DARK_GRAY = '#E0E0E0';
const WHITE = '#FFFFFF';
const BLACK = '#4B4D5F';
const ERROR = '#D32F2F';
const VOILET = '#9447F8';
const LIGHT_BLACK = '#121212';
const DARK_BLUE = '#6B77E1';
const GRAY_LINK = '#A5A6AF';
const SUCCESS = '#04944f';
const BANNER = '#EFF1F7';
const COMPLETED_STATUS = '#FF5C00';
const DANGER_STATUS = '#E80606';


const PADDING = '10px 12px';
const BORDER_RADIUS = '16px !important';
const BUTTON_RADIUS = '20px !important';
const BORDER_COLOR = '#A5A6AF';
const BOX_SHADOW = '0px 0px 20px 0px rgba(208, 208, 208, 0.25)';
const HIGHLIGHT_BORDER = '#576AA4';
const TABLE_BORDER = '#EDEDED';
const PAPER_BORDER_RADIUS = '20px';
const STATUS_BACKGROUD = '#FFF1E9';
// const DRAWER_BOXSHADOW = '0px 0px 20px 0px rgba(208, 208, 208, 0.25)';

// -------------------NEW THEME----------

const withTheme = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        darkMode: false,
      };
    }

    handleTheme = () => {
      this.setState((prevState) => ({ darkMode: !prevState.darkMode }));
    };

    render() {
      const theme = createTheme({
        palette: {
          mode: this.state.darkMode ? 'dark' : 'light',
          primary: {
            main: PRIMARY,
          },
          secondary: {
            main: SECONDARY,
          },
          territory: {
            main: TERRITORY,
          },
          voilet: {
            main: VOILET,
          },
          lightBlack: {
            main: LIGHT_BLACK,
          },
          darkBlue: {
            main: DARK_BLUE,
          },
          darkGrey: {
            main: GRAY_LINK,
          },
          success: {
            main: SUCCESS,
          },
          banner: {
            main: BANNER,
          },
          lightBorder: {
            main: HIGHLIGHT_BORDER,
          },
          common: {
            black: BLACK,
            white: WHITE,
          },
          tableBorder: {
            main: TABLE_BORDER,
          },
          completedStatus: {
            main: COMPLETED_STATUS,
            background: STATUS_BACKGROUD,
          },
          searchBorder: {
            main: DARK_GRAY,
          },
          dangerStatus: {
            main: DANGER_STATUS,
          },
        },
        components: {
          MuiTypography: {
            styleOverrides: {
              root: {
                fontFamily: 'Poppins, sans-serif',
                color: BLACK,
                fontSize: 16,
                fontWeight: 400,
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${BORDER_COLOR}`,
                  borderRadius: BORDER_RADIUS,
                },
                '& .MuiSelect-select': {
                  // paddingRight: "16px !important",
                },
                '& fieldset': {
                  display: 'none',
                  width: 0,
                },
                '&.Mui-error': {
                  borderColor: ERROR,
                },
                paddingLeft: 10,
                background: WHITE,
                border: `1px solid ${DARK_GRAY}`,
                borderRadius: BORDER_RADIUS,
              },
              input: {
                background: WHITE,
                borderRadius: BORDER_RADIUS,
                padding: PADDING,
                paddingLeft: 0,
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                // minWidth: 188,
                '&.MuiInputBase-inputAdornedEnd': {
                  minWidth: 160,
                },
                '&::placeholder': {
                  fontSize: '16px', // Change the font size
                },
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                width: 0,
                display: 'none',
              },
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                margin: 0,
                position: 'absolute',
                bottom: -22,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              },
            },
          },
          MuiInputAdornment: {
            styleOverrides: {
              root: {
                '&.MuiInputAdornment-positionStart': {
                  marginLeft: 8,
                },
                '&.MuiInputAdornment-positionEnd': {
                  marginLeft: -16,
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: BUTTON_RADIUS,
                textTransform: 'none',
                padding: PADDING,
                boxShadow: BOX_SHADOW,
                fontFamily: 'Poppins, sans-serif',
                // color: WHITE,
                fontSize: 16,
                fontWeight: 500,
              },
              contained: {
                backgroundColor: PRIMARY,
                color: WHITE,
                boxShadow: BOX_SHADOW,
                '&:hover': {
                  backgroundColor: DARK_BLUE,
                },
              },
              outlined: {
                background: WHITE,
                color: DARK_BLUE,
                border: `1px solid ${DARK_BLUE}`,
                boxShadow: BOX_SHADOW,
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              root: {
                background: '#fff',
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                textTransform: 'none',
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                background: WHITE,
                border: `1px solid ${DARK_GRAY}`,
                borderRadius: BORDER_RADIUS,
                padding: PADDING,
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: 'DRAWER_BOXSHADOW !important',
                filter: 'none !important',
                // border: `1px solid ${DARK_GRAY}`,
                borderRadius: PAPER_BORDER_RADIUS,
              },
            },
          },
          MuiAutocomplete: {
            styleOverrides: {
              inputRoot: {
                '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
                  padding: '3px', // Change the padding
                  fontFamily: 'Poppins, sans-serif',
                  color: LIGHT_BLACK,
                  fontWeight: 400,
                },
              },
            },
          },
          MuiRadio: {
            styleOverrides: {
              root: {
                '&.Mui-checked': {
                  color: DARK_BLUE, // Use custom color for selected radio
                },
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
              },
            },
          },
          MuiCheckbox: {
            styleOverrides: {
              root: {
                '&.Mui-checked': {
                  color: DARK_BLUE, // Use custom color for selected checkbox
                },
                '&.MuiCheckbox-root': {
                  color: DARK_BLUE, // Change the border color
                },
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
              },
            },
          },
        },
      });

      return (
        <WrappedComponent
          {...this.props}
          theme={theme}
          darkMode={this.state.darkMode}
          handleTheme={this.handleTheme}
        />
      );
    }
  };
};

export default withTheme;
