import React, { useState, Suspense, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import Spinner from 'components/Spinner';
import ThemeContext from 'themes/ThemeContext';
import newTheme from 'themes/index';

import Pages from 'pages';
import store from 'redux/store';

import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import './i18n';


function App({theme}) {
  const [darkMode, setDarkMode] = useState(false);

  const contextValue = useMemo(() => {
    return {
      darkMode,
      setDarkMode,
    };
  }, [darkMode]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Suspense
            fallback={
              <div className="container">
                <Spinner />
              </div>
            }
          >
            <Router>
              <Pages />
            </Router>
          </Suspense>
        </Provider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default newTheme(App);
