import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from 'components/Dialog';
import ModalForm from 'components/ConfirmModal/ModalForm';
import useMediaQuery from '@mui/material/useMediaQuery';

const Index = ({ open, handleClose, ...props }) => {
  const fullScreen = useMediaQuery(props?.theme.breakpoints.down('md'));


  console.log(open,"::open", props)

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      handleClose={handleClose}
      title={t(props?.titleHead)}
      fullWidth={true}
      content={
        <ModalForm
          handleClose={handleClose}
          handleSubmit={props?.handleSubmit}
          confirmMsg={props?.confirmMsg}
          btnMsg={props?.btnMsg}
          fullScreen={fullScreen}
          {...props}
        />
      }
    />
  );
};

export default Index;
