import React from 'react';
import { IconButton, Badge } from '@mui/material';
import NotifyBell from 'assets/svg/notify.svg';

const NotificationBell = ({ count, ...props }) => {
  return (
    <IconButton aria-label="Notifications">
      <Badge badgeContent={String(count)} color="error">
        <img src={NotifyBell} alt="NotifyBell" className={props?.className} />
      </Badge>
    </IconButton>
  );
};

export default NotificationBell;
