import React, { useState } from 'react';
import { useFormik } from 'formik';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import TextField from 'components/TextField';
import Button from 'components/Button';

import validationSchema from 'pages/validations/profile/password';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';

const Form = ({ loading, handleSubmit }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleToggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword((showCurrentPassword) => !showCurrentPassword);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((showNewPassword) => !showNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        required
        id="currentPassword"
        type={showCurrentPassword ? 'text' : 'password'}
        name="currentPassword"
        label={t('Current password')}
        margin="normal"
        autoComplete="off"
        value={formik.values.currentPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.currentPassword &&
          Boolean(formik.errors.currentPassword)
        }
        helperText={
          formik.touched.currentPassword && t(formik.errors.currentPassword)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleCurrentPasswordVisibility}>
                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { maxLength: 50 },
        }}
        fontWeight={500}
        requiredColor={theme?.palette?.voilet?.main}
        labelColor={theme?.palette?.common?.main}
      />

      <TextField
        fullWidth
        required
        id="newPassword"
        type={showNewPassword ? 'text' : 'password'}
        name="newPassword"
        label={t('New password')}
        margin="normal"
        autoComplete="off"
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
        helperText={formik.touched.newPassword && t(formik.errors.newPassword)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleNewPasswordVisibility}>
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { maxLength: 50 },
        }}
        fontWeight={500}
        requiredColor={theme?.palette?.voilet?.main}
        labelColor={theme?.palette?.common?.main}
      />

      <TextField
        fullWidth
        required
        id="confirmPassword"
        type={showConfirmPassword ? 'text' : 'password'}
        name="confirmPassword"
        label={t('Confirm password')}
        margin="normal"
        autoComplete="off"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && t(formik.errors.confirmPassword)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { maxLength: 50 },
        }}
        fontWeight={500}
        requiredColor={theme?.palette?.voilet?.main}
        labelColor={theme?.palette?.common?.main}
      />

      <Button
        fullWidth
        variant="contained"
        size="large"
        type="submit"
        loading={loading}
        sx={{
          background: theme?.palette?.darkBlue?.main,
          minWidth: 140,
          fontSize: 14,
          mt: 2
        }}
      >
        {t('Submit')}
      </Button>
    </form>
  );
};

export default Form;
